import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {Subscription} from 'rxjs';
import {TitlePageService} from '../title-page.service';
import {Settings} from '@common/core/config/settings.service';

@Component({
    selector: 'title-secondary-details-panel',
    templateUrl: './title-secondary-details-panel.component.html',
    styleUrls: ['./title-secondary-details-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TitleSecondaryDetailsPanelComponent implements OnInit, OnDestroy {
    private changeSub: Subscription;
    public description: string;
    public screen_resolution_720p: string;

    constructor(
        public titlePage: TitlePageService,
        private cd: ChangeDetectorRef,
        private settings: Settings,
    ) {}

    ngOnInit() {
        this.changeSub = this.titlePage.changed$.subscribe(() => {
            this.description = (this.titlePage.activeEpisode || this.titlePage.title).description;
            this.cd.markForCheck();
        });
    }

    ngOnDestroy() {
        this.changeSub.unsubscribe();
    }

    clickEvent(url){
        const base = this.settings.getBaseUrl(true) + '/'+url;
        var filename = base.replace(/^.*[\\\/]/, '');


        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', base);
        link.setAttribute('download',filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
}
